import { createContext, useContext } from 'react'
// import axios from 'axios'
// import { ENDPOINTS } from '../api/application-api'


const AuthContext = createContext();
// const LOCAL_STORAGE_ACCESS_TOKEN = 'AUTH_TOKEN';

// const getUser = async () => {
// 	const authToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);

// 	// get User from db

// 	return authToken;
// }

function AuthProvider(props) {

	// const [userStale, setUserStale] = useState(true);
	// const [loading, setLoading] = useState(true);
	// const [user, setUser] = useState(null);

	// useEffect(() => {
	// 	if (userStale) {
	// 		getUser().then(res => {
	// 			setUser(res);
	// 			setLoading(false); // Loading to false must be last step
	// 		})
	// 	}
	// 	setUserStale(false)
	// }, [userStale])
	

	// // 🚨 this is the important bit.
	// // Normally your provider components render the context provider with a value.
	// // But we post-pone rendering any of the children until after we've determined
	// // whether or not we have a user token and if we do, then we render a spinner
	// // while we go retrieve that user's information.
	// if (loading) {
	// 	return <h1>Loading</h1>
	// }

	// const authenticateGithubCode = async (token) => {
	// 	authenticateGithubTokenWithServer(token).then(res => {
	// 		if (res.user && res.user.data && res.user.data.access_token) {
	// 			localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, res.user.data.access_token);
	// 		} else {
	// 			localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
	// 		}
	// 		setUserStale(true);
	// 		setLoading(true);
	// 	})
	// }
	// const logout = () => {
	// 	localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
	// }

	// const data = {user: user}

	// // note, I'm not bothering to optimize this `value` with React.useMemo here
	// // because this is the top-most component rendered in our app and it will very
	// // rarely re-render/cause a performance problem.
	// return (
	// 	<AuthContext.Provider value={{data, loading, logout, authenticateGithubCode}} {...props} />
	// )

	return (
		<AuthContext.Provider value={{}} {...props} />
	);
}

const useAuth = () => useContext(AuthContext)

export {AuthProvider, useAuth}