import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; 

export default function Analytics() {
    const location = useLocation();
    useEffect(() => {
        ReactGA.initialize("G-H75VMNQ5FL");
        ReactGA.send("pageview");
    }, [location]);
    return <div></div>;
}
