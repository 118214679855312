import { AuthProvider } from './auth-context'
import { PageProvider } from './page-context'
import SimpleReactLightbox from 'simple-react-lightbox'

function AppProviders({children}) {
  return (
    <AuthProvider>
      <SimpleReactLightbox>
	      <PageProvider>
		    {children}
		  </PageProvider>
	  </SimpleReactLightbox>
    </AuthProvider>
  )
}

export default AppProviders