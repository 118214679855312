import "./App.css";
import React from "react";
import { lazy, Suspense } from "react";
import { hotjar } from "react-hotjar";
import Analytics from "./Analytics";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

const GenericLanding = lazy(() => import('./GenericLanding'));
const Data = lazy(() => import('./Data'));
const Team = lazy(() => import('./Team'));


export default function App() {
  React.useEffect(() => {
    hotjar.initialize('3058441', 6);
  }, [])

  window.location.href = "https://www.promptlayer.com";

  return (
    <Suspense fallback={null}>
      <Router basename="/">
        <Analytics />
        <Routes>
          <Route path="/" element={<GenericLanding />} />
          <Route path="/team" element={<Team />} />
          <Route path="/data" element={<Data />} />
          <Route path="/sources" element={<Data />} />
          <Route path="*" element={<h1>Error: 404</h1>} />
        </Routes>
      </Router>
    </Suspense>
  );
}
