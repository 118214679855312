import { useState, createContext, useContext } from 'react'

const PageContext = createContext()

const PageProvider = ( props ) => {
  const [title, setTitle] = useState(null);

  return (
  	<PageContext.Provider value={{
      title: title,
      setTitle: setTitle
  	}} {...props} />
  );
}

const usePage = () => useContext(PageContext)

export {usePage, PageProvider}